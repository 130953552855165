import React from "react"
import styles from "./base-details.module.scss"

const Introduction = () => {
  return (
    <div className={styles.container}>
      <h2>An Introduction</h2>
      <p>
      I’m a senior software engineer working on the next generation of products within the car rental industry. I focus on high-performance and availability in modern web services using the best industry standards. 
      <br/>
      <br/>
      Throughout my career I have been an advocate for mentoring, regularly upskilling, and combining creativity with building robust solutions.
      <br/>
      <br/>
      I’m currently expanding my personal development portfolio as lead engineer on a non-profit donation service. I hope to use my love of development to build something special that can help a lot of people. 
      </p>
    </div>
  )
}

export default Introduction
